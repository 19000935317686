import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

import { useState } from 'react';

import logo from '../../assets/icon/Logo.svg';

import { maps, inst, linkedIn, fb } from '../../styles/icons';

import s from './header.module.scss';
import { Helmet } from 'react-helmet';

export const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  return (
    <header className={s.wrapper}>
      {/* <Helmet>
        <title>OffTheGrid - Header</title>
        <meta name="description" content="Header of OffTheGrid website with navigation links and social media icons." />
        <meta property="og:title" content="OffTheGrid Header" />
        <meta property="og:description" content="Header of OffTheGrid website with navigation links and social media icons." />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
        <meta property="og:url" content="https://offthegridlab.com" />
      </Helmet> */}
      <div>
        <Link to='/' onClick={() => setIsOpen(false)}>
          <img alt='Logo' className={s.logo} src={logo} />
        </Link>
      </div>
      {/* {window.innerWidth > 767
        ? ( */}
      <nav className={s.nav}>
        <Link className={s.portfolio} to='/portfolio'>
          Portfolio
          <span className={s.portfolio_quantity}>27+</span>
        </Link>
        <div className={s.pages}>
          <Link to='/'>About us</Link>
          <Link to='/webDev'>Web Developers</Link>
          <div className={s.designed}>
            <button type='button'>Designed</button>
            <div className={s.designed__wrapper}>
              <div className={s.designed_block}>
                <Link to='/design'>UX/UI design</Link>
                <Link to='/pixelart'>2D</Link>
                <Link to='/motion'>Motion design</Link>
                <Link to='/portfolio'>Graphic design</Link>
              </div>
            </div>
          </div>
          {/*  <Link to='/target'>Target</Link> */}
          <Link to='/video_foto'>Video&Photo</Link>
          <Link to='/contacts'>Contacts</Link>
          <Link to='https://offthegridapps.com/' target='_blank'>
            App
          </Link>
        </div>
        <Link to='/contacts'>
          <div className={s.request}>
            <button type='button'>Get a request</button>
            <button type='button'>Request a quote</button>
          </div>
        </Link>
      </nav>
      {/* )
        : ( */}
      <div className={s.nav_mob}>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button className={`${s.burger} ${isOpen ? s.open : ''} `} type='button' onClick={() => setIsOpen(!isOpen)}>
          <div className={`${s.line} ${s.line1} ${isOpen ? s.active : ''} `} />
          <div className={`${s.line} ${s.line2} ${isOpen ? s.active : ''} `} />
        </button>
        {isOpen && (
          <div className={`${s.menu} ${isOpen ? s.open : ''} `}>
            <nav>
              <Link to='/' onClick={() => setIsOpen(false)}>
                About us /<span className={s.link_page__orange}>01</span>
              </Link>
              <Link to='/portfolio' onClick={() => setIsOpen(false)}>
                Portfolio /<span className={s.link_page__pink}>02</span>
              </Link>
              <div className={s.dropdown}>
                <div onClick={() => setIsDropdownOpen(!isDropdownOpen)} className={s.dropdown_trigger}>
                  Solutions /<span>03</span>{' '}
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    className={`${s.dropdown_arrow} ${isDropdownOpen ? s.open : ''} `}
                  >
                    <path d='m6 9 6 6 6-6' />
                  </svg>
                </div>

                <div className={s.dropdown_menu + ' ' + (isDropdownOpen ? s.open : '')}>
                  <Link to='/webDev' onClick={() => setIsOpen(false)}>
                    Web Developers /<span className={s.link_page__green}>03.1</span>
                  </Link>
                  <Link to='/design' onClick={() => setIsOpen(false)}>
                    UX/UI design /<span className={s.link_page__pink}>03.2</span>
                  </Link>
                  <Link to='/motion' onClick={() => setIsOpen(false)}>
                    3D, motion design /<span className={s.link_page__purple}>03.3</span>
                  </Link>
                  <Link to='/target' onClick={() => setIsOpen(false)}>
                    Advertising target /<span className={s.link_page__orange}>03.4</span>
                  </Link>
                  <Link to='/video_foto' onClick={() => setIsOpen(false)}>
                    Video&Photo /<span className={s.link_page__green}>03.5</span>
                  </Link>
                </div>
              </div>
              {/* <Link to="/pixelart" onClick={() => setIsOpen(false)}>
                      2D Pixel Art /
                      <span className={s.link_page__pink}>07</span>
                    </Link> */}
              <Link to='/contacts' onClick={() => setIsOpen(false)}>
                Contacts /<span className={s.link_page__purple}>08</span>
              </Link>
              <Link to='https://offthegridapps.com/' target='_blank'>
                App /<span className={s.link_page__orange}>09</span>
              </Link>
            </nav>
            <Link className={s.contact} to='/contacts' onClick={() => setIsOpen(false)}>
              Contact us
            </Link>
            <div className={s.right}>
              <h3>Follow us on our social networks</h3>
              <div className={s.social}>
                <div className={s.links}>
                  <Link
                    target='_blank'
                    to='https://www.facebook.com/offthegrid.99?mibextid=LQQJ4d'
                    onClick={() => setIsOpen(false)}
                  >
                    <img alt='go to facebook' className={s.link} src={fb} />
                  </Link>
                  <Link
                    target='_blank'
                    to='https://maps.app.goo.gl/yZiRiwtcvYnev1F66?g_st=ic'
                    onClick={() => setIsOpen(false)}
                  >
                    <img alt='go to google maps' className={s.link} src={maps} />
                  </Link>
                  <Link target='_blank' to='https://www.instagram.com/offthegrid_lab' onClick={() => setIsOpen(false)}>
                    <img alt='go to instagram' className={s.link} src={inst} />
                  </Link>
                  <Link target='_blank' to='/dfd' onClick={() => setIsOpen(false)}>
                    <img alt='go to linkedIn' className={s.link} src={linkedIn} />
                  </Link>
                </div>
                <p>2024 OffTheGrid</p>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* )} */}
    </header>
  );
};
