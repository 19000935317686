// @ts-nocheck

import React from 'react';
import { Fade } from 'react-awesome-reveal';
import Tabs from './Tab';
import Web1 from '../assets/profile/Web1.png';
import Web2 from '../assets/profile/Web2.png';
import Web3 from '../assets/profile/Web3.png';
import Web4 from '../assets/profile/Web4.jpg';
import Web5 from '../assets/profile/Web5.jpg';
import Web6 from '../assets/profile/Web6.jpg';
import Web7 from '../assets/profile/Web7.jpg';
import Web8 from '../assets/profile/Web8.jpg';
import Web9 from '../assets/profile/Web9.jpg';
import Web10 from '../assets/profile/Web10.jpg';
import Web11 from '../assets/profile/Web11.png';
import Web12 from '../assets/profile/Web12.png';
import Web13 from '../assets/profile/Web13.png';
import Web14 from '../assets/profile/Web14.png';
import Web15 from '../assets/profile/Web15.png';
import Web16 from '../assets/profile/Web16.png';
import Web17 from '../assets/profile/Web17.png';
import Web18 from '../assets/profile/Web18.jpg';
import Web19 from '../assets/profile/Web19.png';
import Web20 from '../assets/profile/Web20.png';
import Web21 from '../assets/profile/Web21.png';
import Web22 from '../assets/profile/Web22.png';
import Web23 from '../assets/profile/Web23.png';
import Des1 from '../assets/profile/Des1.jpg';
import Des2 from '../assets/profile/Des2.jpg';
import Des3 from '../assets/profile/Des3.jpg';
import Des4 from '../assets/profile/Des4.jpg';
import Des5 from '../assets/profile/Des5.jpeg';
import Des6 from '../assets/profile/Des6.jpeg';
import Des7 from '../assets/profile/Des7.jpeg';
import Des8 from '../assets/profile/Des8.jpeg';
import Des9 from '../assets/profile/Des9.jpeg';
import Des10 from '../assets/profile/Des10.jpeg';
import Des11 from '../assets/profile/Des11.jpeg';
import Des12 from '../assets/profile/Des12.jpeg';
import Des13 from '../assets/profile/Des13.jpeg';
import Des14 from '../assets/profile/Des14.jpeg';
import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom';
import {
  AnchorIcon,
  arrowDown,
  Brush2Icon,
  BrushIcon,
  ClickIcon,
  FigmaIcon,
  HeartIcon,
  ImageIcon,
  PenIcon,
} from '../styles/icons';
import s from '../styles/design.module.scss';
import style from '../styles/portfolio.module.scss';
import { useInView } from 'react-intersection-observer';

const Portfolio: React.FC = () => {
  const [item1, inViewItem1] = useInView();
  const [item2, inViewItem2] = useInView();
  const [item3, inViewItem3] = useInView();
  const [item4, inViewItem4] = useInView();
  const [item5, inViewItem5] = useInView();

  const tabsData = [
    {
      title: 'Website',
      content: {
        title: 'Карточки Блока 1',
        cards: [
          {
            year: 2025,
            imageSrc: Web22,
            title: 'UpJunk',
            features: ['Landing', 'Branding', 'Code'],
            color: 'rgba(0, 149, 64, 1)',
            text: '#fff',
            link: 'https://www.up-junk.com/',
          },
          {
            year: 2025,
            imageSrc: Web21,
            title: 'John’s Auto Body Collision',
            features: ['Landing', 'Auto Body', 'Code'],
            color: 'rgba(255, 0, 0, 1)',
            text: '#fff',
            link: 'https://johnsautobodyinc.com/',
          },
          /*  {
            year: 2025,
            imageSrc: Web20,
            title: 'Zerno',
            features: ['Landing', 'Branding', 'Code'],
            color: 'rgba(91, 60, 36, 1)',
            text: '#fff',
            link: 'https://zernocoffee.com/',
          }, */
          {
            year: 2025,
            imageSrc: Web19,
            title: 'Food Mood',
            features: ['Landing', 'Web Application', 'Code'],
            color: 'rgba(146, 215, 2, 1)',
            text: '#000',
            link: 'https://website.foodmood.menu/',
          },
          {
            year: 2024,
            imageSrc: Web1,
            title: 'Hair Salon',
            features: ['Online Store', 'Beauty', 'Code'],
            color: 'rgba(255, 109, 178, 1)',
            text: '#000',
            link: 'https://hairlashsalon.com/',
          },
          {
            year: 2024,
            imageSrc: Web2,
            title: 'Car Rent',
            features: ['Landing', 'Top Cars', 'Code'],
            color: 'rgba(146, 215, 2, 1)',
            text: '#000',
            link: 'https://easyride.miami/',
          },
          /*     {
            year: 2024,
            imageSrc: Web23,
            title: 'Federal Credit',
            features: ['Landing', 'Credit', 'Code'],
            color: 'rgb(74, 114, 159)',
            text: '#000',
            link: 'https://www.federal-credit.com/',
          }, */
          {
            year: 2024,
            imageSrc: Web3,
            title: 'Powerbank Rent',
            features: ['Landing', 'Powerbank Rent', 'Code'],
            color: 'rgba(255, 121, 0, 1)',
            text: '#000',
            link: 'https://chargemate.me/',
          },
          {
            year: 2024,
            imageSrc: Web4,
            title: 'Insurance Agency',
            features: ['Landing', 'Insurance', 'Code'],
            color: 'rgba(112, 63, 255, 1)',
            text: '#000',
            link: 'https://anikoinsure.com',
          },
          {
            year: 2024,
            imageSrc: Web5,
            title: 'Tires Store',
            features: ['Landing', 'Tires store', 'Code'],
            color: '#fff',
            text: '#000',
            link: 'https://leontires.com',
          },
          {
            year: 2024,
            imageSrc: Web6,
            title: 'Cozy Houses',
            features: ['Website', 'Boooking', 'Code'],
            color: 'rgba(255, 109, 178, 1)',
            text: '#000',
            link: 'https://cozynh.com/',
          },
          {
            year: 2024,
            imageSrc: Web7,
            title: 'Blue Salt Restaurant',
            features: ['Landing', 'Tires Store', 'Code'],
            color: 'rgba(146, 215, 2, 1)',
            text: '#000',
            link: 'http://bluesaltrestaurant.com',
          },
          {
            year: 2024,
            imageSrc: Web8,
            title: 'Igor Catering',
            features: ['Website', 'Boooking', 'Code'],
            color: 'rgba(255, 120, 0, 1)',
            text: '#000',
            link: 'https://igorcatering.net',
          },
          /*    {
            year: 2024,
            imageSrc: Web9,
            title: 'Hotel',
            features: ['Website', 'Hotel', 'Code'],
            color: '#703FFF',
            text: '#000',
            link: 'https://www.stayoncapecod.com',
          }, */
          {
            year: 2024,
            imageSrc: Web10,
            title: 'Detailing Studio',
            features: ['Landing', 'Detailing', 'Code'],
            color: '#FFF',
            text: '#000',
            link: 'https://kuzy53.github.io/detailing/',
          },
          /*   {
            year: 2024,
            imageSrc: Web11,
            title: 'Restaurant',
            features: ['Spanish', 'Food', 'Code'],
            color: 'rgba(255, 109, 178, 1)',
            text: '#000',
            link: '#',
          }, */
          {
            year: 2024,
            imageSrc: Web12,
            title: 'The Medicine',
            features: ['Landing', 'Medicine', 'Code'],
            color: '#FFF',
            text: '#000',
            link: 'https://senior-care-flame.vercel.app/',
          },
          /*  {
            year: 2024,
            imageSrc: Web13,
            title: 'Masters Of Networks',
            features: ['Courses', 'Work', 'Code'],
            color: '#92D702',
            text: '#000',
            link: '#',
          }, */
          {
            year: 2024,
            imageSrc: Web14,
            title: 'Accountant',
            features: ['Accountant', 'Calculations', 'Code'],
            color: '#FF7800',
            text: '#000',
            link: 'https://nexusaccountingcorp.com',
          },
          {
            year: 2024,
            imageSrc: Web15,
            title: 'Iris Hotel Boston',
            features: ['Hotel', 'Service', 'Code'],
            color: '#703FFF',
            text: '#000',
            link: 'https://www.irishotelboston.com',
          },
          {
            year: 2024,
            imageSrc: Web16,
            title: 'Advais Group',
            features: ['Landing', 'Business', 'Code'],
            color: 'rgba(21,44,183)',
            text: '#fff',
            link: 'https://www.advaispartners.com/',
          },
          /*   {
            year: 2024,
            imageSrc: Web17,
            title: 'Sales & Service',
            features: ['Landing', 'Service', 'Code'],
            color: '#bbd6f2',
            text: '#000',
            link: 'https://www.prestige-auto-repair.com/',
          }, */
          {
            year: 2024,
            imageSrc: Web18,
            title: 'Sharing power bank',
            features: ['Landing', 'Powerbank Rent', 'Code'],
            color: 'rgba(255, 121, 0, 1)',
            text: '#000',
            link: 'http://website.battflit.com/',
          },
        ],
      },
    },
    {
      title: 'Graphic design',
      content: {
        title: 'Карточки Блока 2',
        cards: [
          {
            imageSrc: Des1,
            title: 'Business Cards',
            features: ['Design'],
            color: 'rgba(3, 3, 3, 1)',
            text: '#fff',
            link: '',
          },
          {
            imageSrc: Des2,
            title: 'Outdoor Advertising',
            features: ['Design'],
            color: 'rgba(3, 3, 3, 1)',
            text: '#fff',
            link: '',
          },
          {
            imageSrc: Des3,
            title: 'Restaurant Menu',
            features: ['Design'],
            color: 'rgba(3, 3, 3, 1)',
            text: '#fff',
            link: '',
          },
          {
            imageSrc: Des4,
            title: 'Booklets',
            features: ['Design'],
            color: 'rgba(3, 3, 3, 1)',
            text: '#fff',
            link: '',
          },
          {
            imageSrc: Des5,
            title: 'Branding',
            features: ['Design'],
            color: 'rgba(3, 3, 3, 1)',
            text: '#fff',
            link: '',
          },
          {
            imageSrc: Des6,
            title: 'Branding',
            features: ['Design'],
            color: 'rgba(3, 3, 3, 1)',
            text: '#fff',
            link: '',
          },
          {
            imageSrc: Des7,
            title: 'Branding',
            features: ['Design'],
            color: 'rgba(3, 3, 3, 1)',
            text: '#fff',
            link: '',
          },
          { imageSrc: Des8, title: 'Prints', features: ['Design'], color: 'rgba(3, 3, 3, 1)', text: '#fff', link: '' },
          {
            imageSrc: Des9,
            title: 'Branding',
            features: ['Design'],
            color: 'rgba(3, 3, 3, 1)',
            text: '#fff',
            link: '',
          },
          {
            imageSrc: Des10,
            title: 'Calendars',
            features: ['Design'],
            color: 'rgba(3, 3, 3, 1)',
            text: '#fff',
            link: '',
          },
          {
            imageSrc: Des11,
            title: 'Branding',
            features: ['Design'],
            color: 'rgba(3, 3, 3, 1)',
            text: '#fff',
            link: '',
          },
          {
            imageSrc: Des12,
            title: 'Branding',
            features: ['Design'],
            color: 'rgba(3, 3, 3, 1)',
            text: '#fff',
            link: '',
          },
          {
            imageSrc: Des13,
            title: 'Branding',
            features: ['Design'],
            color: 'rgba(3, 3, 3, 1)',
            text: '#fff',
            link: '',
          },
          {
            imageSrc: Des14,
            title: 'Branding',
            features: ['Design'],
            color: 'rgba(3, 3, 3, 1)',
            text: '#fff',
            link: '',
          },
        ],
      },
    },
    {
      title: 'Motion Design',
      content: {
        title: 'Карточки Блока 3',
        cards: [
          // { imageSrc: "/path/to/image1.jpg", title: "Название 1", features: ["Характеристика 1", "Характеристика 2"] },
          // Добавьте другие карточки здесь
        ],
      },
    },
    {
      title: 'SMM',
      content: {
        title: 'Карточки Блока 4',
        cards: [],
      },
    },
  ];

  return (
    <div className={s.portfolio__container}>
      <Helmet>
        <title>Our Projects - Design Portfolio</title>
        <meta
          name='description'
          content='Explore our diverse design projects, including UX/UI design, graphic design, brand design, and more. Our team is dedicated to crafting unique solutions that set your brand apart.'
        />
        <meta
          name='keywords'
          content='design portfolio, UX/UI design, graphic design, brand design, digital design, social media design'
        />
        <meta name='author' content='Our Studio' />
        <meta property='og:title' content='Our Projects - Design Portfolio' />
        <meta
          property='og:description'
          content='Explore our diverse design projects, including UX/UI design, graphic design, brand design, and more. Our team is dedicated to crafting unique solutions that set your brand apart.'
        />
        <meta property='og:image' content='%PUBLIC_URL%/favicon.ico' />
        <meta property='og:url' content='https://offthegridlab.com/portfolio' />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content='Our Projects - Design Portfolio' />
        <meta
          name='twitter:description'
          content='Explore our diverse design projects, including UX/UI design, graphic design, brand design, and more. Our team is dedicated to crafting unique solutions that set your brand apart.'
        />
        <meta name='twitter:image' content='%PUBLIC_URL%/favicon.ico' />

        <script type='application/ld+json'>
          {`
          {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://offthegridlab.com/"
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Portfolio",
                "item": "https://offthegridlab.com/portfolio"
              }
            ]
          }
        `}
        </script>
      </Helmet>

      <div className={style.contents}>
        <h1 className={style.title}>
          <Fade cascade damping={1e-1}>
            OUR PROJECTS
          </Fade>
        </h1>
      </div>

      <Tabs tabs={tabsData} />
      <section className={`${s.about} ${s.about_margin}`}>
        <div className={s.about__top}>
          <img alt='arrow down' src={arrowDown} />
          <div className={s.about_badge}>scroll down</div>
          <img alt='arrow down' src={arrowDown} />
        </div>

        <h3 className={`${s.about_title} ${s.element_none1}`}>
          Our design
          <PenIcon />
          expertise extends
          <AnchorIcon />
          beyond the
          <BrushIcon />
          digital realm
        </h3>
        <h3 className={`${s.about_title} ${s.element_none2}`}>
          Our design
          <PenIcon />
          expertise
          <AnchorIcon />
          extends beyond the digital realm
          <BrushIcon />
        </h3>

        <div className={s.about_blocks}>
          <ClickIcon fill='#703FFF' />
          <span>Digital design</span>
          <HeartIcon fill='#FF6DB2' />
          <span>Brand design</span>
          <span>Graphic design</span>
          <span>Social media design</span>
          <FigmaIcon />
          <ImageIcon />
          <Brush2Icon />
          <span>UX/UI design</span>
        </div>
      </section>
      <section className={s.description}>
        <h2 className={s.description_title}>
          We&apos;re here to bring your brand to life with our exceptional design services
        </h2>
        <p className={s.description_text}>
          Our
          <span>talented team</span>
          is dedicated to crafting visual elements that make
          <span>your brand</span>
          stand out and leave a lasting
          <span>impression</span>.
        </p>
        <div className={s.description_subText}>
          <p>
            Whether you need a memorable logo that encapsulates your company&apos;s essence or eye-catching creative
            posts for your social media, we&apos;re your creative partners.
          </p>
          <Link className={s.link} to='/contacts'>
            Let&apos;s work together
          </Link>
        </div>
      </section>
      <section className={s.services}>
        <h2 className={s.services_title}>
          From identities
          <br />
          to full-scale applications
        </h2>
        <div className={s.services_items}>
          <div ref={item1} className={`${s.item} ${s.item1} ${inViewItem1 && s.item1__bg}`}>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button className={s.switch} type='button'>
              <div className={s.switch_circle} />
            </button>
            <h3 className={s.item_title}>UX/UI design</h3>
            <p className={s.item_description}>
              We don&apos;t take the easy way out. We explore, experiment, and craft unique solutions that set your
              brand apart from the crowd.
            </p>
          </div>
          <div ref={item2} className={`${s.item} ${s.item2} ${inViewItem2 && s.item2__bg}`}>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button className={s.switch} type='button'>
              <div className={s.switch_circle} />
            </button>
            <h3 className={s.item_title}>Graphic design</h3>
            <p className={s.item_description}>
              We don&apos;t take the easy way out. We explore, experiment, and craft unique solutions that set your
              brand apart from the crowd.
            </p>
          </div>
          <div ref={item3} className={`${s.item} ${s.item3} ${inViewItem3 && s.item3__bg}`}>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button className={s.switch} type='button'>
              <div className={s.switch_circle} />
            </button>
            <h3 className={s.item_title}>Brand design</h3>
            <p className={s.item_description}>
              We don&apos;t take the easy way out. We explore, experiment, and craft unique solutions that set your
              brand apart from the crowd.
            </p>
          </div>
          <div ref={item4} className={`${s.item} ${s.item4} ${inViewItem4 && s.item4__bg}`}>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button className={s.switch} type='button'>
              <div className={s.switch_circle} />
            </button>
            <h3 className={s.item_title}>Digital design</h3>
            <p className={s.item_description}>
              We don&apos;t take the easy way out. We explore, experiment, and craft unique solutions that set your
              brand apart from the crowd.
            </p>
          </div>
          <div ref={item5} className={`${s.item} ${s.item5} ${inViewItem5 && s.item5__bg}`}>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button className={s.switch} type='button'>
              <div className={s.switch_circle} />
            </button>
            <h3 className={s.item_title}>Social media design</h3>
            <p className={s.item_description}>
              We don&apos;t take the easy way out. We explore, experiment, and craft unique solutions that set your
              brand apart from the crowd.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Portfolio;
