import React from 'react';
import Marquee from 'react-fast-marquee';

import { Link } from 'react-router-dom';

import s from '../styles/ThankYou.module.scss';

import { Helmet } from 'react-helmet';

const ThankYou = () => (
  <section className={`${s.section}`} id='home'>
    <div className={`${s.background}`}>
      <div className={s.textCenter}>
        <h1 className={s.title}>Thank You! 🎉</h1>
        <p className={s.subtitle}>Your message has been successfully sent. We`ll get back to you soon!</p>
        <a href='/' className={s.back} title='Back to Home' rel='noopener noreferrer' aria-label='Back to Home'>
          Back to Home
        </a>
      </div>
    </div>
  </section>
);

export default ThankYou;
