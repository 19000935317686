import React, { useEffect, useRef, useState } from 'react';
import styles from '../styles/tabs.module.scss';
import s from '../styles/tabs.module.scss';
import Carousel from '../components/Carousel/Carousel';
import PortfolioCard from '../components/PortfolioCard/PortfolioCard';
import Smm1 from '../assets/profile/smm1.png';
import Smm2 from '../assets/profile/smm2.jpg';
import Smm3 from '../assets/profile/smm3.png';
import Smm4 from '../assets/profile/smm4.jpg';
import Smm5 from '../assets/profile/smm5.jpg';
import Smm6 from '../assets/profile/smm6.jpg';
import Smm7 from '../assets/profile/smm7.jpg';
import Smm8 from '../assets/profile/smm8.jpg';
import Smm9 from '../assets/profile/smm9.png';
import Smm10 from '../assets/profile/smm10.jpg';
import Smm11 from '../assets/profile/smm11.png';
import Smm12 from '../assets/profile/smm12.jpg';
import Right from '../assets/profile/Right.svg';
import Left from '../assets/profile/Left.svg';

import { useInView } from 'react-intersection-observer';
import { Helmet } from 'react-helmet';

interface TabContentProps {
  title: string;
  cards: {
    imageSrc: string;
    title: string;
    features: string[];
    color: string;
    text: string;
    link: string;
    year?: number;
  }[];
}
const TabContent: React.FC<TabContentProps> = ({ title, cards }) => {
  return (
    <div className={styles.content}>
      {cards.map((card, index) => (
        <PortfolioCard key={index} {...card} />
      ))}
    </div>
  );
};
const TabContentWeb: React.FC<TabContentProps> = ({ cards }) => {
  const cardRefs = useRef<(HTMLDivElement | null)[]>([]);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [fontSize, setFontSize] = useState<number>(100);

  // Группировка карточек по годам
  const groupedCards = cards.reduce(
    (acc, card) => {
      const year = card.year || 'unknown';
      if (!acc[year]) {
        acc[year] = [];
      }
      acc[year].push(card);
      return acc;
    },
    {} as Record<number | string, typeof cards>,
  );

  // Сортировка по убыванию года
  const sortedEntries = Object.entries(groupedCards).sort(([yearA], [yearB]) => Number(yearB) - Number(yearA));

  const calculateYearPositions = () => {
    let containerWidth = containerRef.current?.offsetWidth || 0;

    if (containerWidth) {
      const calculatedFontSize = containerWidth / 2.5;
      setFontSize(calculatedFontSize);
    }
  };

  useEffect(() => {
    calculateYearPositions();
    window.addEventListener('resize', calculateYearPositions);

    return () => {
      window.removeEventListener('resize', calculateYearPositions);
    };
  }, [cards]);

  return (
    <div ref={containerRef} className={styles.webContent}>
      {sortedEntries.map(([year, yearCards], yearIndex) => (
        <div
          key={yearIndex}
          style={{
            marginTop: yearIndex === 0 ? `-${fontSize / 2}px` : '0px', // Компенсация высоты года
          }}
        >
          {/* Контейнер с относительной позицией */}
          <div
            className={s.yearAbsolute}
            style={{
              fontSize: `${fontSize}px`,
            }}
          >
            {year}
          </div>

          {/* Карточки за этот год */}
          {yearCards.map((card, index) => (
            <ScaleCard ref={(el) => (cardRefs.current[index] = el)} key={index}>
              <div
                className={s.cardWrapper}
                style={{
                  paddingTop: index === 0 ? `${fontSize / 2}px` : undefined,
                }}
              >
                <PortfolioCard {...card} />
              </div>
            </ScaleCard>
          ))}
        </div>
      ))}
    </div>
  );
};

const ScaleCard = React.forwardRef<HTMLDivElement, { children: React.ReactNode }>(({ children }, ref) => {
  const cardRef = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const scaleRef = useRef(0.5); // Храним текущий scale без состояния
  const randomX = useRef(Math.random()); // Генерируем случайное число от 0 до 1
  const [translateX, setTranslateX] = useState(0); // Начальный сдвиг
  const [isHovered, setIsHovered] = useState(false);
  const ticking = useRef(false); // Флаг для requestAnimationFrame

  // Обновляем translateX в зависимости от ширины родителя
  const calculateTranslateX = () => {
    if (wrapperRef.current && cardRef.current) {
      const parentWidth = cardRef.current.offsetWidth; // Ширина родительского контейнера
      const cardWidth = wrapperRef.current.offsetWidth; // Ширина карточки

      const maxShift = Math.max(0, parentWidth - cardWidth); // Максимальный сдвиг
      const randomShift = randomX.current * maxShift; // Рандомный сдвиг в пределах maxShift
      setTranslateX(randomShift);
    }
  };

  // Обновление transform напрямую, минуя setState (для оптимизации)
  const updateScale = (scale: number) => {
    if (cardRef.current) {
      cardRef.current.style.transform = `scale(${scale})`;
      cardRef.current.style.opacity = scale === 1 ? '1' : '0.7';
    }
  };

  // Оптимизация скролла с requestAnimationFrame
  const handleScroll = () => {
    if (cardRef.current && !ticking.current) {
      ticking.current = true;
      requestAnimationFrame(() => {
        const rect = cardRef.current!.getBoundingClientRect();
        if (rect.top < window.innerHeight && rect.bottom > 0) {
          const windowHeight = window.innerHeight;
          const targetPosition = windowHeight * 0.7;
          const cardCenter = rect.top + rect.height / 2;

          if (cardCenter < targetPosition) {
            scaleRef.current = 1;
          } else {
            const distanceFromTarget = Math.abs(cardCenter - targetPosition);
            scaleRef.current = Math.max(0.5, 1 - distanceFromTarget / targetPosition);
          }
          updateScale(scaleRef.current);
        }
        ticking.current = false;
      });
    }
  };

  // Проверяем разрешение экрана и обновляем translateX
  const handleResize = () => {
    const isMobile = window.innerWidth <= 768;
    if (isMobile) {
      setTranslateX(0); // На мобильных translateX = 0
    } else {
      calculateTranslateX(); // Пересчет translateX
    }
  };

  useEffect(() => {
    handleResize();
    calculateTranslateX(); // Вызываем для установки начального положения

    // Слушатели событий
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Запускаем для первого вычисления scale

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      ref={(node) => {
        cardRef.current = node;
        if (ref) {
          if (typeof ref === 'function') ref(node);
          else ref.current = node;
        }
      }}
      className={s.scaleWrapper}
    >
      <div
        ref={wrapperRef}
        className={s.transformWrapper}
        onMouseEnter={() => {
          setIsHovered(true);
          updateScale(1); // Ховер - увеличиваем карточку
        }}
        onMouseLeave={() => {
          setIsHovered(false);
          updateScale(scaleRef.current); // Возвращаем масштаб обратно
        }}
        style={{
          transform: `translateX(${translateX}px)`,
          transition: 'transform 0.3s ease-out',
        }}
      >
        {children}
      </div>
    </div>
  );
});

interface TabsProps {
  tabs: { title: string; content: TabContentProps }[];
}

const Tabs: React.FC<TabsProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [card1, inViewCard1] = useInView();
  const [card2, inViewCard2] = useInView();
  const [card3, inViewCard3] = useInView();

  const metaDescription =
    'Discover our comprehensive social media strategies to increase conversion rates, engage your audience, and drive real results.';
  const pageTitle = tabs[activeTab].title === 'SMM' ? 'SMM Services - Our Projects' : 'Our Projects - Design Portfolio';

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'Service',
    serviceType: 'Social Media Marketing',
    provider: {
      '@type': 'Organization',
      name: 'Our Studio',
      url: 'https://offthegridlab.com/portfolio',
    },
    description: metaDescription,
  };

  return (
    <>
      {/* <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content="social media marketing, SMM, conversion rate, digital marketing, content creation" />
        <meta name="author" content="Our Studio" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
        <meta property="og:url" content="https://offthegridlab.com/portfolio" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content="%PUBLIC_URL%/favicon.ico" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet> */}
      <ul className={styles.tabs}>
        {tabs.map((tab, index) => (
          <li
            key={index}
            className={`${styles.tab} ${index === activeTab ? styles.active : ''}`}
            onClick={() => setActiveTab(index)}
          >
            {tab.title}
          </li>
        ))}
      </ul>
      {tabs[activeTab].title === 'SMM' ? (
        <>
          <div className={styles.container}>
            <h1 className={styles.smm__title}>we will increase the conversion rate of your social networks</h1>

            <div className={`${styles.smm__slider} ${styles.smm__desct}`}>
              <img src={Smm1} alt='Smm1' />
              <img src={Smm2} alt='Smm2' />
              <img src={Smm3} alt='Smm3' />
              <img src={Smm4} alt='Smm4' />
            </div>

            <div className={`${styles.smm__slider} ${styles.smm__mobile}`}>
              <Carousel>
                <img src={Smm1} alt='Smm1' />
                <img src={Smm2} alt='Smm2' />
                <img src={Smm3} alt='Smm3' />
                <img src={Smm4} alt='Smm4' />
              </Carousel>
            </div>

            <section className={s.choice}>
              <div className={s.title__wrapper}>
                <h2 className={s.title}>WHAT WE DO:</h2>
                <div className={s.subtitle}>
                  {/* <p>We are a studio that doesn&apos;t just do the job.</p>
            <p>We create art for your business.</p> */}
                </div>
              </div>
              <div className={s.cards}>
                <div ref={card1} className={`${s.card} ${s.card1} ${inViewCard1 && s.card1__bg}`}>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <button className={s.switch} type='button'>
                    <div className={s.switch_circle} />
                  </button>
                  <div className={s.card__wrapper}>
                    <h4 className={s.card_title}>Personalized Strategies</h4>
                    <p className={s.card_description}>
                      Our team takes the time to understand your brand, audience, and objectives to create strategies
                      that deliver maximum impact
                    </p>
                  </div>
                </div>
                <div ref={card2} className={`${s.card} ${s.card2} ${inViewCard2 && s.card2__bg}`}>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <button className={s.switch} type='button'>
                    <div className={s.switch_circle} />
                  </button>
                  <div className={s.card__wrapper}>
                    <h4 className={s.card_title}>Data-Driven Insights</h4>
                    <p className={s.card_description}>
                      We leverage analytics and industry trance to inform our strategies and optimize your campaigns for
                      optimal performance
                    </p>
                  </div>
                </div>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div ref={card3} className={`${s.card} ${s.card3} ${inViewCard3 && s.card3__bg}`}>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <button className={s.switch} type='button'>
                    <div className={s.switch_circle} />
                  </button>
                  <div className={s.card__wrapper}>
                    <h4 className={s.card_title}>ROI-Focused Results</h4>
                    <p className={s.card_description}>
                      where dedicated to delivering measurable results that drive grow, increase engagement, and
                      generate tangible ROI for your business
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <h1 className={styles.smm__title}>
              {' '}
              with a team of experts, we creating Taylor strategies, that elevate your brand and drive real results
            </h1>

            <section className={`${styles.smm__info} ${styles.smm__border}`}>
              <div className={styles.smm__info__item}>
                <div className={styles.smm_info__left}>
                  <h2>1.</h2>
                  <h1>Social Media Strategy</h1>
                </div>
                <div className={styles.smm_info__right}>
                  <p>
                    We develop customized social media strategies, alliance with your business goals and target. Outline
                    clear objectives and KPIs to measure the success of your show social media campaigns
                  </p>
                </div>
              </div>

              <div className={styles.smm__info__item}>
                <div className={styles.smm_info__left}>
                  <h2>2.</h2>
                  <h1>Content Creation</h1>
                </div>
                <div className={styles.smm_info__right}>
                  <p>
                    Create compelling and engaging content tailored to each social media platform. Produce eye-catching,
                    visuals, informative videos, and captivating copy that resonate with your audience. Implement a
                    content calendar to ensure consistent posting and maintaining brand consistency across our channels
                  </p>
                </div>
              </div>

              <div className={styles.smm__info__item}>
                <div className={styles.smm_info__left}>
                  <h2>2.</h2>
                  <h1>Community Management</h1>
                </div>
                <div className={styles.smm_info__right}>
                  <p>
                    Monitor social media conversations and transfer to identify opportunities for engagement and brand
                    advocacy. Built and nurture relationships with influencers and brand advocates to amplify your
                    message and reach new audiences
                  </p>
                </div>
              </div>

              <div className={styles.smm__info__item}>
                <div className={styles.smm_info__left}>
                  <h2>4.</h2>
                  <h1>Paid Advertising</h1>
                </div>
                <div className={styles.smm_info__right}>
                  <p>
                    Execute targeted page advertising campaigns to increase brand, visibility and drive conversions.
                    Continuously optimize ad performance based on real-time data and insight to maximize ROI
                  </p>
                </div>
              </div>

              <div className={styles.smm__info__item}>
                <div className={styles.smm_info__left}>
                  <h2>5.</h2>
                  <h1>Analytics and Reporting</h1>
                </div>
                <div className={styles.smm_info__right}>
                  <p>
                    Execute targeted page advertising campaigns to increase brand, visibility and drive conversions.
                    Continuously optimize ad performance based on real-time data and insight to maximize ROI
                  </p>
                </div>
              </div>
            </section>

            <h1 className={styles.smm__title}>Our results speak for themselves</h1>
            <div className={styles.smm__after}>
              <h1>before</h1>
              <h1>after</h1>
            </div>

            <div className={`${styles.smm__slider} ${styles.smm__desct}`}>
              <img src={Smm9} alt='Smm9' />
              <img src={Smm10} alt='Smm10' />
              <img src={Smm11} alt='Smm11' />
              <img src={Smm12} alt='Smm12' />
            </div>

            <div className={styles.arrow__smm}>
              <img className={styles.arrow__left} src={Left} alt='Left' />
              <div></div>
              <div></div>
              <div></div>
              <img src={Right} alt='Right' />
            </div>

            <div className={`${styles.smm__slider} ${styles.smm__mobile}`}>
              <Carousel>
                <img src={Smm1} alt='Smm1' />
                <img src={Smm2} alt='Smm2' />
                <img src={Smm3} alt='Smm3' />
                <img src={Smm4} alt='Smm4' />
                <img src={Smm1} alt='Smm5' />
                <img src={Smm2} alt='Smm6' />
                <img src={Smm3} alt='Smm7' />
                <img src={Smm4} alt='Smm8' />
              </Carousel>
            </div>

            <h1 className={styles.smm__title}>we also maintain social media accounts</h1>

            <div className={`${styles.smm__slider} ${styles.smm__desct}`}>
              <img src={Smm5} alt='Smm5' />
              <img src={Smm6} alt='Smm6' />
              <img src={Smm7} alt='Smm7' />
              <img src={Smm8} alt='Smm8' />
            </div>

            <div className={`${styles.smm__slider} ${styles.smm__mobile}`}>
              <Carousel>
                <img src={Smm5} alt='Smm5' />
                <img src={Smm6} alt='Smm6' />
                <img src={Smm7} alt='Smm7' />
                <img src={Smm8} alt='Smm8' />
              </Carousel>
            </div>
          </div>
        </>
      ) : (
        tabs[activeTab].content.cards.length > 0 &&
        (activeTab === 0 ? <TabContentWeb {...tabs[activeTab].content} /> : <TabContent {...tabs[activeTab].content} />)
      )}
    </>
  );
};

export default Tabs;
