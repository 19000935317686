import { Fade } from 'react-awesome-reveal';

import { useState, useEffect, useRef } from 'react';

import { Helmet } from 'react-helmet';

import { useInView } from 'react-intersection-observer';

import { Link } from 'react-router-dom';

import s from '../styles/home.module.scss';

import { Arrow, At, Fire, Folder, Hashtag, Heart, Pixel, Share, Sort, Triangle } from '../styles/icons';

import team1 from '../assets/image/team1.png';
import team2 from '../assets/image/team2.png';
import team3 from '../assets/image/tems3.png';
import team4 from '../assets/image/team4.png';
import team5 from '../assets/image/team5.png';
import chargemate from '../assets/image/chargemate.png';
import page from '../assets/image/page.webp';
import radiant from '../assets/image/radiant.webp';
import chefAlex from '../assets/image/chefAlex.png';
import screens from '../assets/image/screens.jpg';
import sticker from '../assets/image/sticker.webp';
import bag from '../assets/image/bag.jpg';
import Katering from '../assets/image/aniko.webp';
import Aniko from '../assets/image/katering.webp';
import House from '../assets/image/House.png';
import modernn from '../assets/image/modernn.webp';
import MainCard from '../components/mainCard/MainCard';

import Web1 from '../assets/profile/Web1.png';
import Web2 from '../assets/profile/Web2.png';
import Web3 from '../assets/profile/Web3.png';
import Web4 from '../assets/profile/Web4.jpg';
import Web5 from '../assets/profile/Web5.jpg';
import Web6 from '../assets/profile/Web6.jpg';
import Web7 from '../assets/profile/Web7.jpg';
import Web8 from '../assets/profile/Web8.jpg';

import UI from '../assets/icon/UI.svg';
import XD from '../assets/icon/XD.svg';

export interface CardData {
  imageSrc: string;
  title: string;
  features: string[];
  color: string;
  text: string;
  link: string;
  iconSrc: any[];
  btnColor: string;
  description: string;
}

export const Home = () => {
  const [aboutRef, inViewAbout] = useInView();
  const [card1, inViewCard1] = useInView();
  const [card2, inViewCard2] = useInView();
  const [card3, inViewCard3] = useInView();
  const [card4, inViewCard4] = useInView();
  const [card5, inViewCard5] = useInView();
  const containerRef = useRef<HTMLDivElement>(null);
  const blockRef = useRef<HTMLDivElement>(null);

  const [showButton, setShowButton] = useState(false);

  const cardsData: CardData[] = [
    {
      imageSrc: Web3,
      title: 'Powerbank Rent',
      features: ['Landing', 'Powerbank Rent', 'Code'],
      color: 'rgba(227, 214, 76, 1)',
      text: '#000',
      link: 'https://chargemate.me/',
      iconSrc: [],
      btnColor: 'rgba(37, 0, 148, 1)',
      description:
        'Welcome to Charge Mate - your ultimate solution to staying charged on the go! Our website is your one-stop destination for hassle-free power bank rentals, designed to keep your devices juiced up whenever and wherever you need them',
    },
    {
      imageSrc: Web1,
      title: 'Hair Salon',
      features: ['Online Store', 'Beauty', 'Code'],
      color: '#fff',
      text: '#000',
      link: 'https://hairlashsalon.com/',
      iconSrc: [UI],
      btnColor: 'rgba(255, 109, 178, 1)',
      description:
        'Welcome to beauty salon, where we believe in enhancing your natural beauty and making feel confident and beautiful. Radiant salon is dedicated to providing exceptional services and creating a relaxing and rejuvenating experience for our clients',
    },
    {
      imageSrc: Web2,
      title: 'Car Rent',
      features: ['Landing', 'Top Cars', 'Code'],
      color: 'rgba(146, 215, 2, 1)',
      text: '#000',
      link: 'https://easyride.miami/',
      iconSrc: [XD, UI],
      btnColor: 'rgba(222, 107, 3, 1)',
      description:
        'Welcome to beauty salon, where we believe in enhancing your natural beauty and making feel confident and beautiful. Radiant salon is dedicated to providing exceptional services and creating a relaxing and rejuvenating experience for our clients',
    },
    // { imageSrc: Web4, title: "Insurance Agency", features: ["Landing", "Insurance", "Code"], color: "rgba(112, 63, 255, 1)", text: "#000", link: "https://anikoinsure.com", iconSrc: [UI], btnColor: "rgba(255, 109, 178, 1)", description: "Welcome to beauty salon, where we believe in enhancing your natural beauty and making feel confident and beautiful. Radiant salon is dedicated to providing exceptional services and creating a relaxing and rejuvenating experience for our clients" },
    {
      imageSrc: Web5,
      title: 'Tires Store',
      features: ['Landing', 'tires store', 'Code'],
      color: 'rgba(143, 0, 3, 1)',
      text: '#FFF',
      link: 'https://leontires.com',
      iconSrc: [],
      btnColor: 'rgba(74, 87, 98, 1)',
      description:
        'Welcome to beauty salon, where we believe in enhancing your natural beauty and making feel confident and beautiful. Radiant salon is dedicated to providing exceptional services and creating a relaxing and rejuvenating experience for our clients',
    },
    // { imageSrc: Web6, title: "Cozy Houses", features: ["Website", "Boooking", "Code"], color: "rgba(255, 109, 178, 1)", text: "#000", link: "https://cozynh.com/", iconSrc: [UI], btnColor: "rgba(255, 109, 178, 1)", description: "Welcome to beauty salon, where we believe in enhancing your natural beauty and making feel confident and beautiful. Radiant salon is dedicated to providing exceptional services and creating a relaxing and rejuvenating experience for our clients" },
    // { imageSrc: Web7, title: "Blue Salt Restaurant", features: ["Landing", "Tires Store", "Code"], color: "rgba(146, 215, 2, 1)", text: "#000", link: "", iconSrc: [UI], btnColor: "rgba(255, 109, 178, 1)", description: "Welcome to beauty salon, where we believe in enhancing your natural beauty and making feel confident and beautiful. Radiant salon is dedicated to providing exceptional services and creating a relaxing and rejuvenating experience for our clients" },
    // { imageSrc: Web8, title: "Igor Catering", features: ["Website", "Boooking", "Code"], color: "rgba(255, 120, 0, 1)", text: "#000", link: "https://igorcatering.net", iconSrc: [UI], btnColor: "rgba(255, 109, 178, 1)", description: "Welcome to beauty salon, where we believe in enhancing your natural beauty and making feel confident and beautiful. Radiant salon is dedicated to providing exceptional services and creating a relaxing and rejuvenating experience for our clients" }
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (blockRef.current && containerRef.current) {
        const blockRect = blockRef.current.getBoundingClientRect();
        const containerRect = containerRef.current.getBoundingClientRect();

        if (blockRect.bottom >= containerRect.bottom) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>OffTheGrid - Innovative Design Solutions for Your Business</title>
        <meta
          name='description'
          content='OffTheGrid - Innovative Design Solutions for Your Business. We offer Web Development, UX/UI Design, 3D Motion Design, Advertising Target, and more.'
        />
        <meta
          name='keywords'
          content='OffTheGrid, Laboratory of Promotion, Web Development, UX/UI Design, 3D Motion Design, Advertising Target'
        />
        <meta property='og:title' content='OffTheGrid - Innovative Design Solutions for Your Business' />
        <meta
          property='og:description'
          content='OffTheGrid - Innovative Design Solutions for Your Business. We offer Web Development, UX/UI Design, 3D Motion Design, Advertising Target, and more.'
        />
        <meta property='og:image' content='%PUBLIC_URL%/favicon.ico' />
        <meta property='og:url' content='https://offthegridlab.com/' />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content='OffTheGrid - Innovative Design Solutions for Your Business' />
        <meta
          name='twitter:description'
          content='OffTheGrid - Innovative Design Solutions for Your Business. We offer Web Development, UX/UI Design, 3D Motion Design, Advertising Target, and more.'
        />
        <meta name='twitter:image' content='%PUBLIC_URL%/favicon.ico' />
      </Helmet>

      <section className={s.wrapper}>
        <div className={s.contents}>
          <Link className={s.contact} to='/contacts'>
            Contact us
          </Link>
          <h1 className={s.title}>
            <Fade cascade damping={1e-1}>
              OffTheGrid
            </Fade>
          </h1>
          <div className={s.subtitle}>
            Laboratory
            <span> of Promotion</span>
          </div>
        </div>
        <div className={s.services}>
          <Link className={`${s.services_dev} ${s.services_item}`} to='/webDev'>
            <h3 className={s.h3}>Web development</h3>
            <div className={s.services_item__wrapper}>
              <div className={s.service_description}>
                <div className={s.h3Subtitle}>(click on me)</div>
                <div className={s.page}>/01</div>
              </div>
              <div className={s.dev_bg__wrapper}>
                <div className={`${s.dev_bg} ${s.dev_bg1}`} />
                <div className={`${s.dev_bg} ${s.dev_bg2}`} />
                <div className={`${s.dev_bg} ${s.dev_bg3}`} />
                <div className={`${s.dev_bg} ${s.dev_bg4}`} />
                <div className={`${s.dev_bg} ${s.dev_bg5}`} />
                <div className={`${s.dev_bg} ${s.dev_bg6}`} />
                <div className={`${s.dev_bg} ${s.dev_bg7}`} />
                <div className={`${s.dev_bg} ${s.dev_bg8}`} />
                <div className={`${s.dev_bg} ${s.dev_bg9}`} />
                <div className={`${s.dev_bg} ${s.dev_bg10}`} />
                <div className={`${s.dev_bg} ${s.dev_bg11}`} />
                <div className={`${s.dev_bg} ${s.dev_bg12}`} />
                <div className={`${s.dev_bg} ${s.dev_bg13}`} />
                <div className={`${s.dev_bg} ${s.dev_bg14}`} />
                <div className={`${s.dev_bg} ${s.dev_bg15}`} />
                <div className={`${s.dev_bg} ${s.dev_bg16}`} />
                <div className={`${s.dev_bg} ${s.dev_bg17}`} />
                <div className={`${s.dev_bg} ${s.dev_bg18}`} />
              </div>
            </div>
          </Link>
          <Link className={`${s.services_design} ${s.services_item}`} to='/design'>
            <h3 className={s.h3}>UX/UI design</h3>
            <div className={s.services_item__wrapper}>
              <div className={s.service_description}>
                <div className={s.h3Subtitle}>(click on me)</div>
                <div className={s.page}>/02</div>
              </div>
              <div className={s.design_bg__wrapper}>
                <div className={s.design_bg}>
                  <div className={s.design_bg1__wrapper}>
                    <div className={s.design_bg1} />
                  </div>
                </div>
                <div className={s.design_bg}>
                  <div className={s.design_bg2__wrapper}>
                    <div className={s.design_bg2} />
                  </div>
                </div>
                <div className={s.design_bg}>
                  <div className={s.design_bg3__wrapper}>
                    <div className={s.design_bg3} />
                  </div>
                </div>
                <div className={s.design_bg}>
                  <div className={s.design_bg4__wrapper}>
                    <div className={s.design_bg4} />
                  </div>
                </div>
                <div className={s.design_bg}>
                  <div className={s.design_bg5__wrapper}>
                    <div className={s.design_bg5} />
                  </div>
                </div>
                <div className={s.design_bg}>
                  <div className={s.design_bg6__wrapper}>
                    <div className={s.design_bg6} />
                  </div>
                </div>
                <div className={s.design_bg}>
                  <div className={s.design_bg7} />
                </div>
                <div className={s.design_bg}>
                  <div className={s.design_bg8__wrapper}>
                    <div className={s.design_bg8} />
                  </div>
                </div>
                <div className={s.design_bg}>
                  <div className={s.design_bg9__wrapper}>
                    <div className={s.design_bg9} />
                  </div>
                </div>
                <div className={s.design_bg}>
                  <div className={s.design_bg10} />
                </div>
                <div className={s.design_bg}>
                  <div className={s.design_bg11__wrapper}>
                    <div className={s.design_bg11} />
                  </div>
                </div>
                <div className={s.design_bg}>
                  <div className={s.design_bg12__wrapper}>
                    <div className={s.design_bg12} />
                  </div>
                </div>
                <div className={s.design_bg}>
                  <div className={s.design_bg13} />
                </div>
                <div className={s.design_bg}>
                  <div className={s.design_bg14__wrapper}>
                    <div className={s.design_bg14} />
                  </div>
                </div>
                <div className={s.design_bg}>
                  <div className={s.design_bg15__wrapper}>
                    <div className={s.design_bg15} />
                    <div className={s.design_bg15__inner} />
                  </div>
                </div>
              </div>
            </div>
          </Link>
          <Link className={`${s.services_motion} ${s.services_item}`} to='/motion'>
            <h3 className={s.h3}>3D, motion design</h3>
            <div className={s.services_item__wrapper}>
              <div className={s.service_description}>
                <div className={s.h3Subtitle}>(click on me)</div>
                <div className={s.page}>/03</div>
              </div>
              <div className={s.motion_bg__wrapper}>
                <div className={s.motion_bg1}>
                  <div className={s.motion_bg2} />
                </div>
              </div>
            </div>
          </Link>
          <Link className={`${s.services_target} ${s.services_item}`} to='/target'>
            <h3 className={s.h3}>Advertising target</h3>
            <div className={s.services_item__wrapper}>
              <div className={s.service_description}>
                <div className={s.h3Subtitle}>(click on me)</div>
                <div className={s.page}>/04</div>
              </div>
              <div className={s.target_bg__wrapper}>
                <div className={`${s.target_bg} ${s.target_bg1}`}>
                  <div className={s.target_bg1_inner} />
                </div>
                <div className={`${s.target_bg} ${s.target_bg2}`}>
                  <div className={s.target_bg2_inner} />
                </div>
                <div className={`${s.target_bg} ${s.target_bg3}`}>
                  <div className={s.target_bg3_inner} />
                </div>
                <div className={`${s.target_bg} ${s.target_bg4}`}>
                  <div className={s.target_bg4_inner} />
                </div>
              </div>
            </div>
          </Link>
          {/* {window.innerWidth > 767 ? ( */}
          <>
            <Link className={`${s.services_foto} ${s.services_item} ${s.adapt__desc}`} to='/video_foto'>
              <div className={s.services_itemMini__wrapper}>
                <h3 className={s.h3}>Video,photo</h3>
                <div className={s.h3Subtitle}>(click on me)</div>
              </div>
              <div className={s.services_itemMini__bottom}>
                <div className={s.page}>/05</div>
                <div className={s.foto_bg__wrapper}>
                  <div className={s.foto_bg1} />
                  <div className={s.foto_bg2} />
                  <div className={s.foto_bg3} />
                  <Triangle className={s.foto_bg4} />
                </div>
              </div>
            </Link>
            <Link className={`${s.services_pixelArt} ${s.services_item} ${s.adapt__desc}`} to='/pixelArt'>
              <div className={s.services_itemMini__wrapper}>
                <h3 className={s.h3}>2D Pixel Art</h3>
                <div className={s.h3Subtitle}>(click on me)</div>
              </div>
              <div className={s.services_itemMini__bottom}>
                <div className={s.page}>/06</div>
                <div className={s.pixel_bg__wrapper}>
                  <Pixel className={s.pixel_bg} />
                </div>
              </div>
            </Link>
          </>
          {/* ) : ( */}
          <div className={`${s.fotoArt} ${s.adapt__mob}`}>
            <Link className={`${s.services_foto} ${s.services_item} ${s.adapt__mob}`} to='video_foto'>
              <div className={s.services_itemMini__wrapper}>
                <h3 className={s.h3}>Video,photo</h3>
                <div className={s.h3Subtitle}>(click on me)</div>
              </div>
              <div className={s.page}>/05</div>
              <div className={s.foto_bg__wrapper}>
                <div className={s.foto_bg1} />
                <div className={s.foto_bg2} />
                <div className={s.foto_bg3} />
                <Triangle className={s.foto_bg4} />
              </div>
            </Link>
            <Link className={`${s.services_pixelArt} ${s.services_item} ${s.adapt__mob}`} to='pixelArt'>
              <div className={s.services_itemMini__wrapper}>
                <h3 className={s.h3}>2D Pixel Art</h3>
                <div className={s.h3Subtitle}>(click on me)</div>
              </div>
              <div className={s.page}>/06</div>
              <div className={s.pixel_bg__wrapper}>
                <Pixel className={s.pixel_bg} />
              </div>
            </Link>
          </div>
          {/* )} */}
        </div>
      </section>
      <section ref={aboutRef} className={s.about}>
        {/* {window.innerWidth > 767 */}
        {/* ? ( */}
        <h2 className={`${s.about_title} ${s.adapt__desc}`}>
          <span className={s.about_description__green}>Your</span>
          <img alt='icon Fire' src={Fire} />
          <span>studio for comprehensive</span>
          <img alt='icon Arrow' src={Arrow} />
          <span className={s.about_description__orange}>business</span>
          <br />
          <span>promotion in the world</span>
          <br />
          <span>{'of '}</span>
          <span className={s.about_description__pink}>digital</span>
          <img alt='icon Heart' src={Heart} />
          <span>possibilities!</span>
        </h2>
        {/* ) : ( */}
        <h2 className={`${s.about_title} ${s.adapt__mob}`}>
          <span className={s.about_description__green}>Your</span>
          <img alt='icon Fire' src={Fire} />
          <span>studio for</span>
          <br />
          <span>comprehensive</span>
          <br />
          <span className={s.about_description__orange}>business</span>
          <img alt='icon Arrow' src={Arrow} />
          <br />
          <span>promotion in the</span>
          <br />
          <span>world</span>
          <img alt='icon Heart' src={Heart} />
          <span>{'of '}</span>
          <span className={s.about_description__pink}>digital</span>
          <br />
          <span>possibilities!</span>
        </h2>
        {/* )} */}
        <div className={inViewAbout ? s.about_animation : ''}>
          <img alt='icon Sort' className={s.sort} src={Sort} />
          <img alt='icon Folder' className={s.folder} src={Folder} />
          <div className={`${s.versatility} ${s.text_item}`}>Versatility</div>
          <div className={`${s.partner} ${s.text_item}`}>Your Growth Partner</div>
          <img alt='icon Share' className={s.share} src={Share} />
          <div className={`${s.quality} ${s.text_item}`}>Quality and Creativity</div>
          <div className={`${s.creative} ${s.text_item}`}>Creative Solutions</div>
          <div className={`${s.here}  ${s.text_item}`}>Here and Now</div>
          <img alt='icon At' className={s.at} src={At} />
          <img alt='icon Hashtag' className={s.hashtag} src={Hashtag} />
        </div>
      </section>
      <section className={s.choice}>
        <div className={s.title__wrapper}>
          <h2 className={s.title}>When you choose us, you choose:</h2>
          <div className={s.subtitle}>
            <p>We are a studio that doesn&apos;t just do the job.</p>
            <p>We create art for your business.</p>
          </div>
        </div>
        <div className={s.cards}>
          <div ref={card1} className={`${s.card} ${s.card1} ${inViewCard1 && s.card1__bg}`}>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button className={s.switch} type='button'>
              <div className={s.switch_circle} />
            </button>
            <div className={s.card__wrapper}>
              <h4 className={s.card_title}>Creative Solutions</h4>
              <p className={s.card_description}>
                We don&apos;t take the easy way out. We explore, experiment, and craft unique solutions that set your
                brand apart from the crowd.
              </p>
            </div>
          </div>
          <div ref={card2} className={`${s.card} ${s.card2} ${inViewCard2 && s.card2__bg}`}>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button className={s.switch} type='button'>
              <div className={s.switch_circle} />
            </button>
            <div className={s.card__wrapper}>
              <h4 className={s.card_title}>Versatility</h4>
              <p className={s.card_description}>
                From web development to 3D design and marketing, we&apos;ve brought together the best talents in various
                fields to provide you with a complete range of services.
              </p>
            </div>
          </div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div ref={card3} className={`${s.card} ${s.card3} ${inViewCard3 && s.card3__bg}`}>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button className={s.switch} type='button'>
              <div className={s.switch_circle} />
            </button>
            <div className={s.card__wrapper}>
              <h4 className={s.card_title}>Your Growth Partner</h4>
              <p className={s.card_description}>
                We&apos;re not just service providers. We&apos;re your partners in achieving success. Together, we grow
                and evolve.
              </p>
            </div>
          </div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div ref={card4} className={`${s.card} ${s.card4} ${inViewCard4 && s.card4__bg}`}>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button className={s.switch} type='button'>
              <div className={s.switch_circle} />
            </button>
            <div className={s.card__wrapper}>
              <h4 className={s.card_title}>Quality and Creativity</h4>
              <p className={s.card_description}>
                We don&apos;t compromise on quality. Our work is meticulous, and our creative approach gives it
                uniqueness.
              </p>
            </div>
          </div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div ref={card5} className={`${s.card} ${s.card5} ${inViewCard5 && s.card5__bg}`}>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button className={s.switch} type='button'>
              <div className={s.switch_circle} />
            </button>
            <div className={s.card__wrapper}>
              <h4 className={s.card_title}>Here and Now</h4>
              <p className={s.card_description}>
                Whether you need a website, design, or a marketing strategy, we&apos;re ready to start working
                immediately. Your success is our priority.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section ref={containerRef} className={s.teams}>
        <div ref={blockRef} className={s.title__wrapper}>
          <div className={s.badge}>Our team</div>
          <h2 className={s.title}>
            Talented web designers, developers, and project managers who work to bring your vision to life.
          </h2>
          {showButton && (
            <div className={s.buttonContainer}>
              <Link to='/contacts'>
                <button className={s.teams_button} type='button'>
                  Get a request
                </button>
              </Link>
              <div className={s.showButton} />
            </div>
          )}
        </div>
        <div className={s.teams_cards} id='teams'>
          <div className={`${s.teams_card} ${s.teams_card1}`} id='card1'>
            <img alt='Senior Front-end Developer Maria' className={s.teams_card__photo} src={team1} />
            <div className={s.teams_card__wrapper}>
              <p className={s.teams_card__name}>Maria</p>
              <p className={s.teams_card__description}>UX/UI designer</p>
            </div>
          </div>
          <div className={`${s.teams_card} ${s.teams_card2}`}>
            <img alt='Senior Front-end Developer Serge' className={s.teams_card__photo} src={team2} />
            <div className={s.teams_card__wrapper}>
              <p className={s.teams_card__name}>Serge</p>
              <p className={s.teams_card__description}>Senior Front-end Developer</p>
            </div>
          </div>
          <div className={`${s.teams_card} ${s.teams_card3}`}>
            <img alt='Senior Front-end Developer Nico' className={s.teams_card__photo} src={team3} />
            <div className={s.teams_card__wrapper}>
              <p className={s.teams_card__name}>Nico</p>
              <p className={s.teams_card__description}>Senior Front-end Developer</p>
            </div>
          </div>
          <div className={`${s.teams_card} ${s.teams_card4}`}>
            <img alt='Senior Front-end Developer Emma' className={s.teams_card__photo} src={team4} />
            <div className={s.teams_card__wrapper}>
              <p className={s.teams_card__name}>Emma</p>
              <p className={s.teams_card__description}>Senior Front-end Developer</p>
            </div>
          </div>
          <div className={`${s.teams_card} ${s.teams_card5}`}>
            <img alt='Senior Front-end Developer Danial' className={s.teams_card__photo} src={team5} />
            <div className={s.teams_card__wrapper}>
              <p className={s.teams_card__name}>Danial</p>
              <p className={s.teams_card__description}>Developer, promo admin</p>
            </div>
          </div>
        </div>
      </section>

      <section className={s.projects}>
        <div className={s.title__wrapper}>
          <div className={s.badge}>Our projects</div>
          <h2 className={s.title} id='portfolio'>
            Here are a few examples of our past projects:
          </h2>
        </div>

        {cardsData.map((card, index) => (
          <MainCard
            key={index}
            imageSrc={card.imageSrc}
            iconSrc={card.iconSrc}
            description={card.description}
            btnColor={card.btnColor}
            title={card.title}
            features={card.features}
            color={card.color}
            text={card.text}
            link={card.link}
          />
        ))}
      </section>

      {/* <section className={s.projects}>
        <div className={s.title__wrapper}>
          <div className={s.badge}>Our projects</div>
          <h2 className={s.title} id="portfolio">Here are a few examples of our past projects:</h2>
        </div>
        <div className={s.projects_cards}>
          {window.innerWidth > 767
            ? (
              <>
                <div className={s.projects_cards__wrapper}>
                  <Link
                    className={`${s.projects_card} ${s.projects_card1}`}
                    target="_blank"
                    to="https://chargemate.me/"
                  >
                    <img alt="website chargemate" className={s.projects_card__preview} src={chargemate} />
                    <div className={s.projects_card__wrapper}>
                      <h4 className={s.projects_card__title}>Сharge mate</h4>
                      <p className={s.projects_card__description}>
                        - Sharing power bank Company
                        <br />
                        - Website
                        <br />
                        - App
                        <br />
                        - marketing
                      </p>
                    </div>
                  </Link>
                  <Link
                    className={`${s.projects_card} ${s.projects_card5}`}
                    target="_blank"
                    to="https://anikoinsure.com"
                  >
                    <img alt="Katering" className={s.projects_card__preview} src={Katering} />
                    <div className={s.projects_card__wrapper}>
                      <h4 className={s.projects_card__title}>Aniko</h4>
                      <p className={s.projects_card__description}>
                        - Website
                      </p>
                    </div>
                  </Link>
                </div>
                <div className={s.projects_cards__wrapper}>
                  <Link
                    className={`${s.projects_card} ${s.projects_card2}`}
                    target="_blank"
                    to="https://chefalex.co/"
                  >
                    <img alt="website chefAlex" className={s.projects_card__preview} src={chefAlex} />
                    <div className={s.projects_card__wrapper}>
                      <h4 className={s.projects_card__title}>Chef Alex</h4>
                      <p className={s.projects_card__description}>
                        - Miami Catering
                        <br />
                        - Website
                      </p>
                    </div>
                  </Link>
                  <Link
                    className={`${s.projects_card} ${s.projects_card6}`}
                    target="_blank"
                    to="https://easyride.miami/"
                  >
                    <img alt="EasyRide" className={s.projects_card__preview} src={screens} />
                    <div className={s.projects_card__wrapper}>
                      <h4 className={s.projects_card__title}>EasyRide</h4>
                      <p className={s.projects_card__description}>
                        - Rent Car Miami
                        <br />
                        - website
                        <br />
                        - admin system
                        <br />
                        - marketing
                        <br />
                        - target
                        <br />
                      </p>
                    </div>
                  </Link>
                </div>
                <div className={s.projects_cards__wrapper}>
                  <Link
                    className={`${s.projects_card} ${s.projects_card3}`}
                    target="_blank"
                    to="https://hairlashsalon.com/"
                  >
                    <img alt="Radiant" className={s.projects_card__preview} src={radiant} />
                    <div className={s.projects_card__wrapper}>
                      <h4 className={s.projects_card__title}>Radiant</h4>
                      <p className={s.projects_card__description}>
                        - Website
                      </p>
                    </div>
                  </Link>
                  <Link
                    className={`${s.projects_card} ${s.projects_card7}`}
                    target="_blank"
                    to="https://igorcatering.net"
                  >
                    <img alt="Igor Catering" className={s.projects_card__preview} src={Aniko} />
                    <div className={s.projects_card__wrapper}>
                      <h4 className={s.projects_card__title}>Igor Catering</h4>
                      <p className={s.projects_card__description}>
                        - Website
                        <br />
                        - Catering
                        <br />
                      </p>
                    </div>
                  </Link>
                </div>
                <div className={s.projects_cards__wrapper}>
                  <Link
                    className={`${s.projects_card} ${s.projects_card4}`}
                    target="_blank"
                    to="https://leontires.com"
                  >
                    <img alt="Leon Tires" className={s.projects_card__preview} src={bag} />
                    <div className={s.projects_card__wrapper}>
                      <h4 className={s.projects_card__title}>Leon Tires</h4>
                      <p className={s.projects_card__description}>
                        - Market used tires
                        <br />
                        - website
                        <br />
                        - Marketing
                        <br />
                      </p>
                    </div>
                  </Link>
                  <Link
                    className={`${s.projects_card} ${s.projects_card8}`}
                    target="_blank"
                    to="https://cozynh.com/"
                  >
                    <img alt="Cozy Houses" className={s.projects_card__preview} src={House} />
                    <div className={s.projects_card__wrapper}>
                      <h4 className={s.projects_card__title}>Cozy Houses</h4>
                      <p className={s.projects_card__description}>
                        - Rent House
                        <br />
                        - website
                        <br />
                      </p>
                    </div>
                  </Link>
                </div>
              </>
            ) : (
              <>
                <Link
                  className={`${s.projects_card} ${s.projects_card1}`}
                  target="_blank"
                  to="https://chargemate.me/"
                >
                  <img alt="Сharge mate" className={s.projects_card__preview} src={chargemate} />
                  <div className={s.projects_card__wrapper}>
                    <h4 className={s.projects_card__title}>Сharge mate</h4>
                    <p className={s.projects_card__description}>
                      - Sharing power bank Company
                      <br />
                      - Website
                      <br />
                      - App
                      <br />
                      - marketing
                    </p>
                  </div>
                </Link>
                <Link
                  className={`${s.projects_card} ${s.projects_card5}`}
                  target="_blank"
                  to="https://hairlashsalon.com/"
                >
                  <img alt="Radiant" className={s.projects_card__preview} src={radiant} />
                  <div className={s.projects_card__wrapper}>
                    <h4 className={s.projects_card__title}>Radiant</h4>
                    <p className={s.projects_card__description}>
                      - Website
                    </p>
                  </div>
                </Link>
                <Link
                  className={`${s.projects_card} ${s.projects_card2}`}
                  target="_blank"
                  to="https://chefalex.co/"
                >
                  <img alt="website chefAlex" className={s.projects_card__preview} src={chefAlex} />
                  <div className={s.projects_card__wrapper}>
                    <h4 className={s.projects_card__title}>Chef Alex</h4>
                    <p className={s.projects_card__description}>
                      - Miami Catering
                      <br />
                      - Website
                    </p>
                  </div>
                </Link>
                <Link
                  className={`${s.projects_card} ${s.projects_card4}`}
                  target="_blank"
                  to="https://leontires.com"
                >
                  <img alt="Leon Tires" className={s.projects_card__preview} src={bag} />
                  <div className={s.projects_card__wrapper}>
                    <h4 className={s.projects_card__title}>Leon Tires</h4>
                    <p className={s.projects_card__description}>
                      - Market used tires
                      <br />
                      - website
                      <br />
                      - Marketing
                      <br />
                    </p>
                  </div>
                </Link>
                <Link
                  className={`${s.projects_card} ${s.projects_card6}`}
                  target="_blank"
                  to="https://easyride.miami/"
                >
                  <img alt="EasyRide" className={s.projects_card__preview} src={screens} />
                  <div className={s.projects_card__wrapper}>
                    <h4 className={s.projects_card__title}>EasyRide</h4>
                    <p className={s.projects_card__description}>
                      - Rent Car Miami
                      <br />
                      - website
                      <br />
                      - admin system
                      <br />
                      - marketing
                      <br />
                      - target
                      <br />
                    </p>
                  </div>
                </Link>
                <Link
                  className={`${s.projects_card} ${s.projects_card8}`}
                  target="_blank"
                  to="https://cozynh.com/"
                >
                  <img alt="Cozy Houses" className={s.projects_card__preview} src={House} />
                  <div className={s.projects_card__wrapper}>
                    <h4 className={s.projects_card__title}>Cozy Houses</h4>
                    <p className={s.projects_card__description}>
                      - Rent House
                      <br />
                      - website
                      <br />
                    </p>
                  </div>
                </Link>
                <Link
                  className={`${s.projects_card} ${s.projects_card7}`}
                  target="_blank"
                  to="https://igorcatering.net"
                >
                  <img alt="Igor Catering" className={s.projects_card__preview} src={Aniko} />
                  <div className={s.projects_card__wrapper}>
                    <h4 className={s.projects_card__title}>Igor Catering</h4>
                    <p className={s.projects_card__description}>
                      - Website
                      <br />
                      - Catering
                      <br />
                    </p>
                  </div>
                </Link>

                <Link
                  className={`${s.projects_card} ${s.projects_card5}`}
                  target="_blank"
                  to="https://anikoinsure.com"
                >
                  <img alt="Aniko" className={s.projects_card__preview} src={Katering} />
                  <div className={s.projects_card__wrapper}>
                    <h4 className={s.projects_card__title}>Aniko</h4>
                    <p className={s.projects_card__description}>
                      - Website
                    </p>
                  </div>
                </Link>

              </>
            )}
        </div>
      </section> */}
    </>
  );
};
